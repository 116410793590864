import React from 'react';

import Wellness from '../components/serviceDetails/Wellness'
import WellnessPricing from '../components/serviceDetails/WellnessPricing'
import WellnessBreadCumb from '../components/serviceDetails/WellnessBreadCumb'
import WellnessFAQ from '../components/serviceDetails/WellnessFAQ'
// import BlogsHome from '../components/BlogsHome'
import Newsletter from '../components/NewsLetter'

function ServicesWellness() {
  return (
	  <main>
		<WellnessBreadCumb/>
		<Wellness/>
		<WellnessPricing/>
		<WellnessFAQ/>
		{/* <BlogsHome/> */}
		<br></br><br></br><br></br><br></br>
		<Newsletter/>
	  </main>
  )
}

export default ServicesWellness;
