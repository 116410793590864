/* global $ */
import React, { useEffect } from "react";
import {Link} from 'react-router-dom'
import PricingCard from './PricingCard'

function WellnessPricing() {

  return (
    <section className="pricing-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <div className="section-icon"><img src="img/icon/pawprint.png" alt="" /></div>
                <h5 className="sub-title">Price List</h5>
                <h2 className="title">Physical Wellness Exam</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <PricingCard title="Physical Wellness Exam" price="39" label1="Physical Wellness Exam" availability="Monday - Saturday"/>
            <PricingCard title="Recheck Physical Wellness Exam" price="28" label1="Within 3 months of first Office visit" availability="Monday - Saturday"/>

          </div>
        </div>
    </section>
  )
}

export default WellnessPricing;
