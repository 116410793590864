import React from 'react';
import ServicesSidebar from './ServicesSidebar';

function Boarding() {
  return (
	   <section className="breeder-details-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="breeder-details-content" >
                <h4 className="title animated animatedFadeInUp fadeInUp">Pet Boarding</h4>
                <h6 className="subtitle animated animatedFadeInUp fadeInUp">Sunshine Animal Clinic</h6>
                <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                  <div className="services-detail-content">
                    <p>Our veterinary hospital offers dog boarding and cat boarding if you are leaving town and are worried about your pet receiving the right care, monitoring, and/or medication. Our facilities are ideal for cats and small breed dogs, and our staff members have a wealth of experience in providing care (many have pets with medical conditions).</p>
                    <p>Sunshine Animal Clinic is convenient if you are looking for dog or cat boarding anywhere in the Jacksonville area. If you have any further questions about boarding, please feel free to <a href="tel:0987654321">call us!</a></p>
                    <br></br>
                    <h4 className="title animated animatedFadeInUp fadeInUp">Cost for Cat Boarding</h4>
                    <h6 className="subtitle animated animatedFadeInUp fadeInUp">Cattery Services</h6>
                    <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                    <h6><i className="fa fa-chevron-circle-right" /><strong> One Cat</strong> per night: $25</h6>
                    <br></br>
                    <h4 className="title animated animatedFadeInUp fadeInUp">Cost for Dog Boarding</h4>
                    <h6 className="subtitle animated animatedFadeInUp fadeInUp">Kennel Services</h6>
                    <span className="service-headings-line animated animatedFadeInUp fadeInUp"> </span>
                    <h6><i className="fa fa-chevron-circle-right" /><strong> One Dog</strong> per night: $35</h6>
                    <br></br>
                    <p>*Prices do not include taxes. All cats and dogs must to be up to date on vaccines before boarding. Pet boarding costs may be higher for some treatments or monitoring, for example diabetic monitoring.</p>
                  </div>
              </div>
            </div>
            <div className="col-lg-4">
              <ServicesSidebar />
            </div>
          </div>
        </div>
      </section>
  )
}

export default Boarding;
